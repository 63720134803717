// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".goodbyeOverlay-3-NKZ{max-width:480px;overflow-x:auto}.header-2SINR{font-size:24px}@media screen and (min-width: 600px){.header-2SINR{font-size:2.57143em}}.video-HNFcG{max-width:100%}\n", ""]);
// Exports
exports.locals = {
	"goodbyeOverlay": "goodbyeOverlay-3-NKZ",
	"header": "header-2SINR",
	"video": "video-HNFcG"
};
module.exports = exports;
